import Cookies from "js-cookie";
import React, { useReducer, createContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Networking from "./pages/Networking";

export const AuthContext = createContext();

const initialState = {
  isAuthenticated: Cookies.get("user") ? true : false,
  user: Cookies.get("user"),
  token: Cookies.get("accessToken"),
  expires_in: parseInt(Cookies.get("expires_in"))
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGOUT":
      localStorage.clear();
      console.log("runn")
      Cookies.remove("accessToken");
      Cookies.remove("user");
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    default:
      return state;
  }
};

export default function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <AuthContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Networking />} />
        </Routes>
      </BrowserRouter>
    </AuthContext.Provider>
  );
}
