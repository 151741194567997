import React, { useEffect, useContext } from "react";
import { Col, Container, Image, Row, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";

import { AuthContext } from "../App";
import NavigationBar from "../components/NavigationBar";

import "./Networking.scss";
import profesionalnetworking from "../assets/images/networking/profesionnal-networking.png";
import peermentorship from "../assets/images/networking/peermentorship.png";

export default function Networking() {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(AuthContext);
  // const timeout = () => {
  //   setTimeout(() => {
  //     console.log("Token telah berakhir");
  //     dispatch({
  //       type: "LOGOUT",
  //     });
  //   }, state.tokenExpires);
  // };

  // useEffect(() => {
  //   // eslint-disable-next-line
  //   timeout();
  //   // eslint-disable-next-line
  // }, []);
  console.log(state);

  if (!state.isAuthenticated) {
    window.location.href = "https://learn.gened.info/";
  }
  return (
    <>
      <NavigationBar />
      <Container fluid="lg" className="mt-5">
        <Row className="profesionalnetworking">
          <Col md={6} className="m-auto">
            <Image src={profesionalnetworking} fluid />
          </Col>
          <Col md={6}>
            <div className="content">
              <h1>{t("profesional_networking_title")}</h1>
              <p>{t("profesional_networking_description")}</p>
              <Button
                href="https://docs.google.com/forms/d/1H72MwJ6lgXvBRrz7jIlehG3b8_LX7pHh0O76XvJCB1I/edit"
                className="join-button"
              >
                {t("profesional_networking_join_button")}
              </Button>
            </div>
          </Col>
        </Row>
        <Row className="peermentorship">
          <Col md={6}>
            <div className="content">
              <h1>{t("peer_mentorship_title")}</h1>
              <p>{t("peer_mentorship_description")}</p>
              <Button
                href="https://docs.google.com/forms/d/1H72MwJ6lgXvBRrz7jIlehG3b8_LX7pHh0O76XvJCB1I/edit"
                className="join-button"
              >
                {t("peer_mentorship_join_button")}
              </Button>
            </div>
          </Col>
          <Col md={6} className="m-auto">
            <Image src={peermentorship} fluid />
          </Col>
        </Row>
      </Container>
    </>
  );
}
