import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  Nav,
  Navbar,
  NavDropdown,
  DropdownButton,
  Dropdown,
  Container,
} from "react-bootstrap";

import { AuthContext } from "../App";

import "./NavigationBar.scss";
import Logo from "../assets/images/navigationbar/gened-logo.svg";
import UserPlaceholder from "../assets/images/user-placeholder.jpeg";
import idFlag from "../assets/images/id-flag.svg";
import usaFlag from "../assets/images/usa-flag.svg";

function NavigationBar() {
  const [navbar, setNavbar] = useState(false);
  const [locale, setLocale] = useState("id");
  const { i18n } = useTranslation();
  const { dispatch } = useContext(AuthContext);

  const addNavbarShadow = () => {
    if (window.scrollY > 0) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", addNavbarShadow);

  const handleSelect = (e) => {
    console.log(e);
    setLocale(e);
    i18n.changeLanguage(e);
  };

  let navFlag;
  console.log(locale)
  if (locale === "id") {
    navFlag = <img src={idFlag} alt="id flag" />;
  } else {
    navFlag = <img src={usaFlag} alt="en flag" />;
  }

  return (
    <Navbar className={"custom-navbar"} expand="md" collapseOnSelect variant="light">
      <Container className='navbar-container'>
        <Navbar.Brand href="https://gened.info" className='d-flex flex-row align-items-center mr-0'>
          <img className="custom-navbar__logo" alt="logo" src={Logo} />
        </Navbar.Brand>
        <DropdownButton
              title={navFlag}
              className="language-picker"
              onSelect={handleSelect}
            >
              <Dropdown.Item eventKey="en">
                <img src={usaFlag} alt="en flag" /> English
              </Dropdown.Item>
              <Dropdown.Item eventKey="id">
                <img src={idFlag} alt="id flag" /> Indonesian
              </Dropdown.Item>
              
            </DropdownButton>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className='w-100 justify-content-between'>
          <Nav className="me-auto w-100 justify-content-center">
            <Nav.Link href="https://gened.info/id">Home</Nav.Link>
            <Nav.Link href="https://learn.gened.info/dashboard">Dashboard</Nav.Link>
            <NavDropdown title="Program" id="collasible-nav-dropdown">
              <NavDropdown.Item href="https://learn.gened.info">
                Innovation Learning
              </NavDropdown.Item>
              <NavDropdown.Item href="https://library.gened.info">
                Innovation Library
              </NavDropdown.Item>
              <NavDropdown.Item href="https://community.gened.info">
                Innovation Community
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="https://gened.info/maintenance">Leaderboard</Nav.Link>
          </Nav>
          <Nav>
            <NavDropdown
              className="profile-dropdown"
              title={
                <div className="user-profile">
                  <img
                    className="user-img"
                    src={UserPlaceholder}
                    alt="user pic"
                  />
                </div>
              }
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item eventKey={1.1} href="#profile">
                Profile
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey={1.1}
                href="https://learn.gened.info"
              >
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
